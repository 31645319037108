<template>
  <div class="row">
    <div class="col-md-12" style="background: white">
      <div class="row mt-5">
        <div class="col-md-2 pl-2 pr-0">
          <div class="form-group">
            <input
              @keypress.enter="appData()"
              type="date"
              v-model="date_from"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-md-2 pl-2 pr-0">
          <div class="form-group">
            <input
              @keypress.enter="appData()"
              type="date"
              v-model="date_to"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-2 pl-2 pr-0">
          <div class="input-group-append">
            <button
              class="btn btn-outline-info"
              type="button"
              @click="search()"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
        <div class="col-md-6 pl-2 pr-0">
          <div class="input-group-append float-right mr-5">
            <router-link
              :to="{ name: 'client.visitorlogs.create', params: { id: 0 } }"
            >
              <div class="btn btn-info" style="float: right">
                <i class="fa fa-plus"></i> Add Visitor
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th style="width: 5%">SN</th>
            <th scope="col">Date</th>
            <th scope="col">Name</th>
            <th scope="col">In Time</th>
            <th scope="col">Out Time</th>
            <th scope="col">Visit To</th>
            <th scope="col">Reason</th>
            <th scope="col">Card No.</th>

            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody v-if="!getLoadingData && filteredVisitorLogs.length > 0">
          <tr v-for="(item, index) in filteredVisitorLogs" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.date }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.in_time }}</td>
            <td>{{ item.out_time }}</td>
            <td>{{ item.for_whom }}</td>
            <td>{{ item.visit_reason }}</td>
            <td>{{ item.card_number }}</td>

            <td>
              <button
                @click="editData(item.id)"
                class="btn btn-warning btn-sm mr-3"
              >
                Edit
              </button>
              <button
                @click="ViewItem(item.id, index)"
                class="btn btn-info btn-sm"
              >
                View
              </button>
              <button
                @click="deleteItem(item.id)"
                class="btn btn-sm btn-danger ml-2"
                style="cursor: pointer"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>

        <tbody v-if="!getLoadingData && filteredVisitorLogs.length < 1">
          <tr>
            <td colspan="10" class="text-center">No record(s) found</td>
          </tr>
        </tbody>

        <tbody v-if="getLoadingData">
          <tr>
            <th class="text-center" colspan="10">
              <vDataLoader></vDataLoader>
            </th>
          </tr>
        </tbody>
        <!----><!---->
      </table>
      <hr v-if="pagination.total_page > 1" />
      <vl-pagination
        v-on:selected="selectedPage"
        v-on:previous="previousPage"
        v-on:first="firstPage"
        v-on:last="lastPage"
        v-on:next="nextPage"
        v-if="pagination.total_page > 1"
        :pagination="pagination"
      ></vl-pagination>
    </div>
    <visitLogDetailModal
      :key="key"
      v-if="visitLogDetailModalShow"
      v-on:closemodal="closeModal"
      :loading-data="loadingData"
      :has-data="hasData"
      :show-modal="visitLogDetailModalShow"
      :visit-log-detail="visitLogDetail"
      :index-num="currentIndex"
    ></visitLogDetailModal>
  </div>
</template>

<script>
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  FETCH_VISITOR,
  FETCH_VISITOR_LOGS,
  DELETE_VISIT
} from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";
import vlPagination from "@/includes/table/Pagination";
import visitLogDetailModal from "@/components/frontend/modal/visitorlogdetail";
export default {
  data() {
    return {
      key: 0,

      date_from: this.$moment(new Date()).add(-7, "days").format("YYYY-MM-DD"),
      date_to: this.$moment(new Date()).format("YYYY-MM-DD"),
      pagination: {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15,
      },
      visitLogDetailModalShow: false,
      filteredVisitorLogs: [],
      visitLogDetail: {},
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard",
        },
        {
          id: 2,
          title: "",
          route: "",
        },
      ],
      loadingData: false,
      getLoadingData: false,
      hasData: false,
      searchInput: "",
    };
  },
  components: {
    vDataLoader,
    visitLogDetailModal,
    vlPagination,
  },
  computed: {
    ...mapGetters(["currentUser"]),
    // filteredVisitorLogs() {
    //   let tempPromocode =;
    //   //process search input
    //   if (this.searchInput != "" && this.searchInput) {
    //     tempPromocode = tempPromocode.filter(item => {
    //       return (
    //         item.promo_code
    //           .toUpperCase()
    //           .includes(this.searchInput.toUpperCase()) ||
    //         item.company_name
    //           .toUpperCase()
    //           .includes(this.searchInput.toUpperCase())
    //       );
    //     });
    //   }
    //   return tempPromocode;
    // }
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.getVisitorLogsData();
    
  },
  filters: {
    convertDateFormat(value) {
      return moment(value).format("MM/DD/YYYY");
    },
  },
  methods: {
    search() {
      this.pagination = {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15,
      };
      this.getVisitorLogsData();
    },
    selectedPage(n) {
      this.pagination.current_page = n;
      this.getVisitorLogsData();
    },
    nextPage() {
      this.pagination.current_page = this.pagination.current_page + 1;
      this.getVisitorLogsData();
    },
    lastPage() {
      this.pagination.current_page = this.pagination.total_page;
      this.getVisitorLogsData();
    },
    firstPage() {
      this.pagination.current_page = 1;
      this.getVisitorLogsData();
    },
    previousPage() {
      this.pagination.current_page = this.pagination.current_page - 1;
      this.getVisitorLogsData();
    },
    closeModal() {
      this.visitLogDetailModalShow = false;
    },
    editData(id) {
      this.$router.push({
        name: "client.visitorlogs.edit",
        params: { id: id },
      });
    },
    // deleteItem(id, index) {
    //   this.$swal({
    //     title: "Are you sure?",
    //     text: "Once deleted, you will not be able to recover !",

    //     confirmButtonText: "Delete",
    //     cancelButtonText: "Cancel",
    //     showCancelButton: true,
    //     showCloseButton: true,
    //     everseButtons: true
    //   }).then(willDelete => {
    //     if (willDelete.isConfirmed) {
    //       this.$store.dispatch(DELETE_PROMOCODE, id).then(data => {
    //         this.$toastr.s(data.msg);
    //         this.getPromocodes.splice(index, 1);
    //       });
    //     }
    //   });
    // },
    // editData(id) {
    //   this.$router.push({ name: "client.promocode.edit", params: { id: id } });
    // },
    ViewItem(appId, index) {
      this.currentIndex = index;
      this.visitLogDetailModalShow = true;
      this.selectedAppId = appId;
      this.loadingData = true;
      // var data = {
      //   id: appId
      // };

      this.$store
        .dispatch(FETCH_VISITOR, appId)
        .then((res) => {
          this.visitLogDetail = res.data;
          this.loadingData = false;
          this.hasData = true;
          this.key += 1;
        })
        .catch(() => {
          this.loadingData = false;
        });
    },
    getVisitorLogsData() {
      var _data = {
        date_from: this.date_from,
        date_to: this.date_to,
        page: this.pagination.current_page,
      };
      this.getLoadingData = true;
      this.$store
        .dispatch(FETCH_VISITOR_LOGS, _data)
        .then((data) => {
          this.filteredVisitorLogs = data.data;
          this.pagination.total_records = data.total_record;
          this.pagination.total_page = data.page_range;
          this.pagination.received_per_page_data = data.data.length;
          this.pagination.per_page_data = 15;

          this.getLoadingData = false;
        })
        .catch(() => {
          this.getLoadingData = false;
        });
    },
    deleteItem(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover!",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((willDelete) => {
        if (willDelete.isConfirmed) {
          this.$store
            .dispatch(DELETE_VISIT, id)
            .then(() => {
              this.$toastr.s("Deleted Successfully");
              this.getVisitorLogsData();
            })
            .catch((err) => {
              this.errors = err;
            });
        }
      });
    },
    // deleteItem(id) {
    //   this.$swal({
    //     title: "Are you sure?",
    //     text: "Once deleted, you will not be able to recover!",
    //     buttons: true,
    //     dangerMode: true,
    //     showCancelButton: true,
    //     cancelButtonText: "No",
    //     confirmButtonText: "Yes",
    //   }).then((willDelete) => {
    //     if (willDelete.isConfirmed) {
    //       this.$store
    //         .dispatch(DELETE_DAILY_MIXING_REPORT, id)
    //         .then(() => {
    //           this.$toastr.s("Deleted Successfully");
    //           this.getVisitorLogsData();
    //         })
    //         .catch((err) => {
    //           this.errors = err;
    //         });
    //     }
    //   });
    // },
  },
};
</script>

<style></style>
